//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModel: () => import("@/components/XModal"),
    mb: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  props: {
    //规则版本信息
    selRow: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    operationName: {
      type: String | Number,
      default: 1,
    },
    activeItem: {
      type: String,
      default: "部门",
    },
    id: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      loadForm: {
        Words: [undefined, undefined, undefined, undefined, undefined],
        ProgressGroupIds: [],
        Behavior: null,
        BehaviorInCheck: null,
        rType: 1,
        UserIds:[]
      },
      processOptions: [],
 
      copyForm: "",
      subLoading: false,
      loading: false,
    };
  },
  methods: {
    getUser(arr) {
      this.loadForm.UserIds = arr;
    },
    async GetProgressGroup() {
      const resp = await this.$http.post(
        "/User/GetProgressGroupSelected.ashx",
        { teamId: this.teamValue, configId: this.selRow.Id }
      );
      if (resp.res == 0) {
        this.processOptions = resp.data;
      }
    },
    beforeOpen() {
      this.loading = false;
      this.GetProgressGroup();
      if (this.operationName == 2) {
        this.getDetail();
      }
    },
    getDetail() {
      this.loading = true;
      this.$http
        .post("/BehaviorAnalyse/GetBehaviorAnalyseDetail.ashx", {
         Id: this.id, teamId: this.teamValue
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            if (this.activeItem == "部门") {
              this.loadForm.rType = 1;
              this.loadForm.ProgressGroupIds = res.data.PreocessGroup.map((m) => m.ID);
            } else {
              this.loadForm.rType = 2;
         
              this.loadForm.UserIds = [];
              res.data.UserData.forEach((item) => {
                this.loadForm.UserIds.push({
                  Name: item.Name,
                  UsId: item.UsId,
                });
              });
            }
            this.loadForm.Behavior = res.data.Behavior;
            this.loadForm.BehaviorInCheck = res.data.BehaviorInCheck;
            this.loadForm.Words = res.data.Words;       
          }
        });
    },
    init() {
      Object.assign(this.$data, this.$options.data());
    },
    addMore() {
      let arr = this.loadForm.Words.filter((item) => {
        return item == undefined || "";
      });
      if (!arr.length) {
        this.loadForm.Words.unshift(undefined);
      }
    },
    filterParams(params) {
      let _params = { ...params };

      for (let key in _params) {
        if (_params[key] instanceof Array) {
          _params[key] = JSON.stringify(_params[key]);
        }
      }
      return _params;
    },
    check() {},
    //提交
    submit() {
      if (this.subLoading) {
        return;
      }
      let bool = this.copyForm == JSON.stringify(this.loadForm);
      if (bool && this.operationName == 2) {
        this.$modal.hide("actionRuleXmodal");
        return;
      } else {
        let word = [];
        this.loadForm.Words.forEach((item) => {
          //关键词
          if (item) {
            word.push(item);
          }
        });
     
        let flag = false;
        let str = "";
        if (this.loadForm.ProgressGroupIds.length == 0 && this.rType == 1) {
          flag = true;
          str = "请选择作用部门";
        }
        if (this.loadForm.UserIds.length == 0 && this.rType == 2) {
          flag = true;
          str = "请选择作用成员";
        }
        if (!this.loadForm.Behavior) {
          flag = true;
          str = "请输入行为名称";
        }
        if (flag) {
          this.$message({
            type: "warning",
            message: str,
          });
          return;
        }

        this.subLoading = true;
        let params = {
          Id: this.id,
          Behavior: this.loadForm.Behavior,
          ProgressGroupIds: this.loadForm.rType == 1 ? this.loadForm.ProgressGroupIds : [],
          UserIds:
            this.loadForm.rType == 2
              ? this.loadForm.UserIds.map((item) => item.UsId)
              : [],
          BehaviorInCheck: this.loadForm.BehaviorInCheck,
          teamId: this.teamValue,
          configId: this.selRow.Id,
          Words: word,
        };
        params = this.filterParams(params);
        this.$http
          .get("/BehaviorAnalyse/SaveBehaviorAnalyse.ashx", {
            params,
          })
          .then((res) => {
            this.subLoading = false;
            if (res.res == 0) {
              this.$modal.hide("actionRuleXmodal");
              this.$emit("update");
              this.$message({
                type: "success",
                message: this.operationName == 1 ? "添加成功" : "修改成功",
              });
            }
          });
      }
    },
  },
  mounted() {},
  created() {},
};
